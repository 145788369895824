import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import Layout from '../components/Layout/Layout'
import SEO from "../components/seo"
import Img from 'gatsby-image'

const NotFoundPage = ({data, location}) => (
  <Layout location={location}>
    <SEO title="Page Not Found" />
    <section className="container mx-auto px-8">
      <div className="page-title text-center">Page not found</div>
      {data?.fileName?.childImageSharp?.fluid &&
        <div
          className="mb-5 mt-5 max-w-md m-auto"
        >
          <Img fluid={
            data.fileName.childImageSharp.fluid
          } />
        </div>
      }
      <p className="text-center">
        You just hit a page that does not exist... the sadness.
      </p>
    </section>
  </Layout>
)

NotFoundPage.propTypes = {
  data: PropTypes.object,
  location: PropTypes.object,
}

export default NotFoundPage

export const query = graphql`
  query {
    fileName: file(relativePath: { eq: "404.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 600) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }`